.results {
  flex-grow: 1;
  width: 90%;
  margin: 10px auto;
}

.results p {
  color: #777;
  text-align: center;
}

.results img {
  height: 32px;
  margin: 20px 0px;
}

@media (max-width: 768px) {
  .results {
    width: 90%;
  }
}
@media (min-width: 1500px){
  .results {
    width: 70%;
  }
}
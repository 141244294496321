
.search {
  margin: 30px auto;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}

.search input {
  width: 30%;
  height: 40px;
  font-size: 1.5em;
  padding: 10px 20px;
}

.search button {
  background-color: #0575E6;
  border: none;
  border-radius: 3px;
  color: #fff;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  font-weight: bold;
  margin: 0px 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .search {
    flex-direction: column;
    margin: 10px auto;
  }
  .search input {
    width: 80%;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1.25em;
  }
}
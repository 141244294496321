.mainSwatch {
  padding: 10px;
  background: white;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 40px 85px rgba(0,0,0,0.3);
}
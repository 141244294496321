.selectionPreview {
  width: 100%;
  background: white;
  text-align: left;
  padding: 3em 6em;
  border-radius: 0;
  transform: translateX(-40px);
  border-radius: 4px;
}

.previewBox {
  border-radius: 10px;
  background: white;
  padding: 1.5em 3em;
  box-shadow: 0 40px 85px rgba(0,0,0,0.3);
}

.selectionPreview p {
  text-align: left;
  margin: 20px 0px;
}

hr {
  border-top: 2px solid;
}
.selectionPreview p.previewTitle {
  margin: 0;
  font-size: .75em;
}

.primaryButton {
  font-size: .8em;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin: 2px 0;
  margin-right: 5px;

  border: solid 1px transparent;
  border-radius: 4px;

  padding: .75em 2.25em;

  color: #ffffff;
  background-color: #9555af;
}

.previewHeader {
  margin-top: 0.25em;
}

.secondaryButton {
  font-size: .8em;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin: 5px 0;

  color:  #9555af;
  border: solid 1px #9555af;
  border-radius: 4px;

  padding: .75em 2.25em;

  background-color: transparent;
}

@media (min-width: 1500px){
  .selectionPreview {
    width: 60%;
    padding: 4em 6em;
  }
  .previewBox {
    padding: 2em 6em;
  }
}
.colorSwatch {
  height: 60px;
  width: 30px;
  margin: 15px 5px;
  display: inline-block;
  border: 3px solid white;
  border-radius: 30px;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, .2);
  cursor: pointer;
  text-align: center;
}

.colorSwatch p {
  font-size: 12px;
  position: relative;
  top: -25px;
  margin: 0;
  left: 0;
  text-transform: uppercase;
}
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900');

body {
  background-color: #eee;
}

.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main {
  display: flex;
  flex-direction: column;
}

.dropzone {
  color: #777;
  border: 1px dashed #0575E6;
  border-radius: 3px;
  margin: 40px auto;
  background: #fff;
  padding: 20px 50px;
  width: 75%;
  cursor: pointer;
}

.previewImg {
  height: 200px;
  border: 1px solid grey;
}

@media (max-width: 768px) {
  .dropzone {
    width: 100%;
    padding: 20px 0px;
  }
}
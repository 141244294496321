.mainResults {
  display: flex;
  margin: 30px 0px;
}
.mainResults ul {
  z-index: 5;
  padding: 0;
  text-align: left;
}
.mainResults ul li {
  list-style: none;
  transition: all 1s ease-in-out;
}
.mainColorSquare {
  height: 30px;
  width: 30px;
  border-radius: 4px;
}
.mainSwatch .content {
  font-size: 0.75em;
  margin-left: 10px;
  color: #666;
  text-transform: uppercase;
}
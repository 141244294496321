.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.nav ul li {
  display: inline-block;
  margin: 0px 20px;
  cursor: pointer;
}

.nav ul li * {
  pointer-events: none;
}

.nav ul li.active {
  border-bottom: 4px solid #0575E6;
}

@media (max-width: 768px) {
  .nav ul li {
    font-size: .75em;
  }
  .nav ul li h2 {
    margin: 15px 0px;
  }
}
.imgPreview p {
  display: block;
}

.previewImg {
  height: 150px;
}

.searchSection {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-bottom: 40px;
}

.searchSection h1 {
  font-size: 3em;
  color: #0575E6;
  margin-bottom: 0;
}

.searchSection a {
  text-decoration: none;
}

.searchSection p {
  margin-top: 0px;
}

.searchSection.minimized {
  flex-direction: row;
  padding: 0px 40px;
  transition: 0.5s all;
}

.searchSection.minimized .nav h2 {
  font-size: 1em;
}

.searchSection.minimized p {
  display: none;
}

.searchSection.minimized h1 {
  margin: 0;
}

.searchSection.minimized .search {
  margin: 0;
  width: 50%;
  flex-grow: 1;
}
.searchSection.minimized h1 {
  font-size: 1.75em;
}

.searchSection.minimized .search input {
  width: 40%;
  height: 30px;
}

.searchSection.minimized .logo {
  width: 20%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 20px;
}

.logo h1 {
  font-weight: 900;
  font-size: 2.75em;
}

.logo * {
  margin: 0 5px;
}

@media (max-width: 768px) {
  .searchSection.minimized .search input {
    width: 80%;
  }
  .searchSection {
    padding: 0px 20px;
  }
  .searchSection.minimized {
    flex-direction: column;
  }
  .searchSection.minimized .search {
    width: 100%;
  }
  .logo, .searchSection.minimized .logo {
    width: 100%;
  }
}
.copyButton {
  display: inline-block;
  padding: 5px;
  color: #00CD8F;
  border: 1px solid #00CD8F;
  border-radius: 3px;
  text-align: center;
  outline: none;
  text-decoration: none;
  -webkit-transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.copyButton:hover, .copyButton.copied {
  color: #fff;
  background-color: rgba(0, 205, 144, 0.61);
  border-color:  rgba(0, 205, 144, 0.61);
  transition: background-color 0.3s ease-in, border-color 0.3s ease-in;
}

.copyToClipboard {
  margin-left: 20px;
}
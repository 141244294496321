.resultType {
  margin: 20px 0px;
  text-align: left;
}

.resultType .title {
  margin: 20px 0px;
}

.resultType .title * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.search {
  margin: 30px auto;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}

.search input {
  width: 30%;
  height: 40px;
  font-size: 1.5em;
  padding: 10px 20px;
}

.search button {
  background-color: #0575E6;
  border: none;
  border-radius: 3px;
  color: #fff;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  font-weight: bold;
  margin: 0px 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .search {
    flex-direction: column;
    margin: 10px auto;
  }
  .search input {
    width: 80%;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1.25em;
  }
}
.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.nav ul li {
  display: inline-block;
  margin: 0px 20px;
  cursor: pointer;
}

.nav ul li * {
  pointer-events: none;
}

.nav ul li.active {
  border-bottom: 4px solid #0575E6;
}

@media (max-width: 768px) {
  .nav ul li {
    font-size: .75em;
  }
  .nav ul li h2 {
    margin: 15px 0px;
  }
}
.imgPreview p {
  display: block;
}

.previewImg {
  height: 150px;
}

.searchSection {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-bottom: 40px;
}

.searchSection h1 {
  font-size: 3em;
  color: #0575E6;
  margin-bottom: 0;
}

.searchSection a {
  text-decoration: none;
}

.searchSection p {
  margin-top: 0px;
}

.searchSection.minimized {
  flex-direction: row;
  padding: 0px 40px;
  transition: 0.5s all;
}

.searchSection.minimized .nav h2 {
  font-size: 1em;
}

.searchSection.minimized p {
  display: none;
}

.searchSection.minimized h1 {
  margin: 0;
}

.searchSection.minimized .search {
  margin: 0;
  width: 50%;
  flex-grow: 1;
}
.searchSection.minimized h1 {
  font-size: 1.75em;
}

.searchSection.minimized .search input {
  width: 40%;
  height: 30px;
}

.searchSection.minimized .logo {
  width: 20%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 20px;
}

.logo h1 {
  font-weight: 900;
  font-size: 2.75em;
}

.logo * {
  margin: 0 5px;
}

@media (max-width: 768px) {
  .searchSection.minimized .search input {
    width: 80%;
  }
  .searchSection {
    padding: 0px 20px;
  }
  .searchSection.minimized {
    flex-direction: column;
  }
  .searchSection.minimized .search {
    width: 100%;
  }
  .logo, .searchSection.minimized .logo {
    width: 100%;
  }
}
.sk-circle {
  margin: 40px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.results {
  flex-grow: 1;
  width: 90%;
  margin: 10px auto;
}

.results p {
  color: #777;
  text-align: center;
}

.results img {
  height: 32px;
  margin: 20px 0px;
}

@media (max-width: 768px) {
  .results {
    width: 90%;
  }
}
@media (min-width: 1500px){
  .results {
    width: 70%;
  }
}
.colorSwatch {
  height: 60px;
  width: 30px;
  margin: 15px 5px;
  display: inline-block;
  border: 3px solid white;
  border-radius: 30px;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, .2);
  cursor: pointer;
  text-align: center;
}

.colorSwatch p {
  font-size: 12px;
  position: relative;
  top: -25px;
  margin: 0;
  left: 0;
  text-transform: uppercase;
}
.copyButton {
  display: inline-block;
  padding: 5px;
  color: #00CD8F;
  border: 1px solid #00CD8F;
  border-radius: 3px;
  text-align: center;
  outline: none;
  text-decoration: none;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.copyButton:hover, .copyButton.copied {
  color: #fff;
  background-color: rgba(0, 205, 144, 0.61);
  border-color:  rgba(0, 205, 144, 0.61);
  transition: background-color 0.3s ease-in, border-color 0.3s ease-in;
}

.copyToClipboard {
  margin-left: 20px;
}
.resultType {
  margin: 20px 0px;
  text-align: left;
}

.resultType .title {
  margin: 20px 0px;
}

.resultType .title * {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}
.mainSwatch {
  padding: 10px;
  background: white;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 40px 85px rgba(0,0,0,0.3);
}
.selectionPreview {
  width: 100%;
  background: white;
  text-align: left;
  padding: 3em 6em;
  border-radius: 0;
  -webkit-transform: translateX(-40px);
          transform: translateX(-40px);
  border-radius: 4px;
}

.previewBox {
  border-radius: 10px;
  background: white;
  padding: 1.5em 3em;
  box-shadow: 0 40px 85px rgba(0,0,0,0.3);
}

.selectionPreview p {
  text-align: left;
  margin: 20px 0px;
}

hr {
  border-top: 2px solid;
}
.selectionPreview p.previewTitle {
  margin: 0;
  font-size: .75em;
}

.primaryButton {
  font-size: .8em;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin: 2px 0;
  margin-right: 5px;

  border: solid 1px transparent;
  border-radius: 4px;

  padding: .75em 2.25em;

  color: #ffffff;
  background-color: #9555af;
}

.previewHeader {
  margin-top: 0.25em;
}

.secondaryButton {
  font-size: .8em;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin: 5px 0;

  color:  #9555af;
  border: solid 1px #9555af;
  border-radius: 4px;

  padding: .75em 2.25em;

  background-color: transparent;
}

@media (min-width: 1500px){
  .selectionPreview {
    width: 60%;
    padding: 4em 6em;
  }
  .previewBox {
    padding: 2em 6em;
  }
}
.mainResults {
  display: flex;
  margin: 30px 0px;
}
.mainResults ul {
  z-index: 5;
  padding: 0;
  text-align: left;
}
.mainResults ul li {
  list-style: none;
  transition: all 1s ease-in-out;
}
.mainColorSquare {
  height: 30px;
  width: 30px;
  border-radius: 4px;
}
.mainSwatch .content {
  font-size: 0.75em;
  margin-left: 10px;
  color: #666;
  text-transform: uppercase;
}
body {
  background-color: #eee;
}

.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main {
  display: flex;
  flex-direction: column;
}

.dropzone {
  color: #777;
  border: 1px dashed #0575E6;
  border-radius: 3px;
  margin: 40px auto;
  background: #fff;
  padding: 20px 50px;
  width: 75%;
  cursor: pointer;
}

.previewImg {
  height: 200px;
  border: 1px solid grey;
}

@media (max-width: 768px) {
  .dropzone {
    width: 100%;
    padding: 20px 0px;
  }
}
